import { createRouter, createWebHistory, type RouteLocationNormalized } from "vue-router";

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/",
			name: "home",
			redirect: { name: "file", params: { id: "new" } },
		},
		{
			path: "/login/",
			name: "login",
			component: () => import("./views/Login.vue"),
		},
		{
			path: "/file/:id(\\d+|new)/",
			name: "file",
			component: () => import("./views/Home.vue"),
			props: (route: RouteLocationNormalized) => {
				return {
					id:
						route.params.id !== "new"
							? Number.parseInt(<string>route.params.id)
							: undefined,
				};
			},
			meta: {
				scrollable: false,
			},
		},
		{
			path: "/view/:id(\\d+)/",
			name: "view",
			component: () => import("./views/View.vue"),
			props: (route: RouteLocationNormalized) => {
				return {
					id:
						route.params.id !== "new"
							? Number.parseInt(<string>route.params.id)
							: undefined,
				};
			},
			meta: {
				scrollable: false,
			},
		},
		{
			path: "/files/",
			name: "files",
			component: () => import("./views/Files.vue"),
		},
		{
			path: "/:requestedPath(.*)",
			name: "404",
			component: () => import("./views/404.vue"),
			props: (to: RouteLocationNormalized) => ({
				requestedPath: "/" + to.params.requestedPath[0],
			}),
		},
	],
});

export default router;
