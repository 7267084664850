import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { type AppRouter } from "./router";

export type ApiClient = ReturnType<typeof createApiClient>;
export function createApiClient(url: string) {
	return createTRPCProxyClient<AppRouter>({
		links: [
			httpBatchLink({
				url,
			}),
		],
	});
}
