import { type App, type InjectionKey } from "vue";
import { type ApiClient, createApiClient as serverCreateApiClient } from "@lum/tst-server";
import { injectStrict } from "../lib/injectStrict";

const appKey = Symbol.for("Tst:Api") as InjectionKey<ApiClient>;

export function useApiClient(): ApiClient {
	return injectStrict(appKey);
}

export function createApiClient(url: string) {
	return {
		install(app: App) {
			app.provide(appKey, serverCreateApiClient(url));
		},
	};
}
